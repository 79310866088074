body {
  font-family: 'Open Sans', sans-serif;
  background-color: #FFF;
  color: rgb(127, 140, 141);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857142857143;
  letter-spacing: -.005em;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  text-decoration-skip: ink;
}
img {
    max-width: 100%;
    height: auto;
}
#map {
    min-height: 710px;
}
.btn-outline-secondary {
    border-color: #8e8e8e;
    color: #8e8e8e;
}
a {
    color: rgb(127, 140, 141);
    transition: all ease-in-out 0.3s;
}
a:hover {
    text-decoration: none;
    transition: all ease-in-out 0.3s;
    color: #002e4f;
}

/* HEADER */

.header-outer-wrapper {
    background-color: #e1e2e3;
    .site-title {
        font-weight: 800;
        font-size: 61px;
        margin-bottom: 0;
        .main-title-text1 {
            color: #008c2e;
        }
        .main-title-text2 {
            color: #002e4f;
        }
    }
    .site-subtitle {
        font-size: 61px;
        font-weight: 300;
    }
}
.header-cards {
    .card {
        min-height: 100%;
        margin: 5px;
        min-height: 205px;
        transition: all ease-in-out 0.3s;
        &:hover {
            background-color: #008c2e;;
            transition: all ease-in-out 0.3s;
        }
    }
    .left-card {
        text-align: right;
    }
    .right-card {
        text-align: left;
    }
    .card-body {
        position: relative;
        overflow: hidden;
        > a {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            > .header-card-link {
                position: absolute;
                left: 20px;
                top: 50%;
                transform: translatey(-50%);
            }
        }
    }
    .left-card .card-body a .header-card-link {
        left: auto;
        right: 20px;
    }
}
.card {
    background-color: transparent;
}
.custom-text-bolded {
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 0;
    margin-bottom: 10px;
}
.custom-text-big {
    font-size: 39px;
    font-weight: 300;
    line-height: 1em;
    display: block;
}
.custom-text-big-right-card {
    font-size: 31px; 
}
.header-cards .social-card {
    &:hover {
        background-color: transparent;
    }
    .card-body {
        padding: 28px 68px;
    }
    .social-icon {
        width: 63px;
        height: 65px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        transition: all ease-in-out 0.3s;
        transform: scale(1, 1);
        &:hover {
            transition: all ease-in-out 0.3s;
            transform: scale(1.05, 1.05);
        }
    }
    .facebook-icon {
        background-image: url("../images/fb_icon.png");
    }
    .youtube-icon {
        background-image: url("../images/yt_icon.png");
    }
    .pinterest-icon {
        background-image: url("../images/pin_icon.png");
    }
    .instagram-icon {
        background-image: url("../images/insta_icon.png");
    }
}
.header-bottom {
    padding-bottom: 205px;
    .scroll-down-btn {
        width: 52px;
        height: 37px;
        background-image: url("../images/triangle.png");
        cursor: pointer;
        transition: all ease-in-out 0.3s;
        &:hover {
            animation: MoveUpDown 1s ease-in-out infinite;
            transition: all ease-in-out 0.3s;

        }
    }
}
@keyframes MoveUpDown {
  0%, 100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(30%);
  }
}
#section1 {
    background-color: #ecf0f1;
    padding-bottom: 85px;
    > .container {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}
.section2-top-bar {
    margin-top: 70px; 
}
.section3 {
    background-image: url("../images/fancy_bg.png");
    background-repeat: repeat-y; 
}
.image-link-helper { 
    position: relative;
    .image-link-overlay,
    .image-link-overlay a {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .overlay-content {
        opacity: 0;
        z-index: 2;
        font-size: 70px;
        position: absolute;
        left: 50%;
        max-width: 100%;
        top: 50%;
        padding: 40px 70px;
        background-color: rgba(255, 255, 255, 0.4);
        transform: translate(-50%, -50%);
        transition: all ease-in-out 0.3s;
    }
    .image-link-overlay:hover .overlay-content{
        opacity: 1;
        transition: all ease-in-out 0.3s;
    }
}
.application-form-btn {
    max-width: 639px;  
    .custom-text-big {
        font-size: 30px;
        font-weight: 400;
    }
    a {
        display: block;
    }
}
.section3-title {
    font-size: 60px;
    margin-top: 80px;
    margin-bottom: 10px;
    span {
        font-size: 60px;
    }
}
.section3-symbol-img {
    max-width: 30px;
} 
.footer-top {
    .custom-text-big {
        font-size: 30px;
    }
}

.footer-bottom {
    background-color: #f3f3f3;
    font-family: 'Montserrat', sans-serif;
    line-height: 2em;
    font-weight: 600;
    font-size: 12px;
    .desktop-hidden .custom-text-big {
        font-size: 30px;
    }
}
footer {
    .footer-logo-wraper {
        max-height: 100px;
        max-width: 100%;
        overflow: hidden
    }
    .footer-logo-item {
        position: relative;
        min-height: 100px;
        min-width: 150px;
        margin-left: 20px;
        margin-right: 20px;
        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
.footer-copyright {
    background-color: #c0bebe;
}
.copyright {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 12px;
    font-weight: 500;
}
.footer-bottom .desktop-hidden .custom-text-big {
    margin-top: 25px;
    margin-bottom: 10px;
}

/* responsive */

@media screen and (min-width: 992px) {
    .desktop-hidden {
        display: none;
    }
}
@media screen and (max-width: 991px) {
    .mobile-hidden {
        display: none;
    }
}
@media screen and (max-width: 576px) {
    .image-link-helper .overlay-content,
    .section3-title span,
    .section3-title,
    .header-outer-wrapper .site-subtitle,
    .header-outer-wrapper .site-title {
        font-size: 35px;
    }
    .custom-text-big {
        font-size: 29px;
    }
    .application-form-btn .custom-text-big {
        font-size: 25px;
    }
    #map {
        min-height: 460px;
    }
    .footer-bottom .desktop-hidden .custom-text-big {
        font-size: 23px;
    }
}
@media screen and (max-width: 767px) { 
    footer.footer {
        margin-top: -30px;
    }
}